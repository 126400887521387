import APIS from "../../http/apis";
import { Toast } from "vant";


const state = () => ({
    userInfoData: {}, //用户个人信息
    footerList: [], //底部list
    // token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    memberId: null,  // id
    chatData: null, // 直播间数据
    userActiveIndex: 0,  // 直播间数据
    listGift: [],    // 礼物
    isGiftsEffects: 0,  // 设置礼物id
    moneyRefresh: 0,   //  余额更新
    watchFun: false,  // 是否调用过一次初始化视频
    isEffects: true,  // 开关特性
    isLivePage: true,  // 全屏开关
    giftTop3: [],   // 排行榜
    carEffectList: [], // vip座驾特效
    showVideoCover: true,//是否显示cover封面
    isLoading: true, //是否显示loading
    liveDataList: [], //直播列表数据
    isShowChatBox: false, //是否显示聊天输入框
    isLoginChatSuccess: false, //是否已登录聊天室
    mainSwitch: true,  //特效总开关
    isShowEffect: true, //清屏特效展示
    isShowSetInfo: false, //是否显示充值组件
    previewConfig: {}, //直播预览图
    selectValue: '', //选中二级页面
    isShowGirl: false, //是否展示首页美女
});

const mutations = {
    SET_USER_DATA(state, value) {
        state.userInfoData = value;
    },
    M_UPDATA_FOOTER(state, val) {
        state.footerList = val
    },
    M_TOKEN(state, val) {
        state.token = val
    },
    SET_MEMBER_ID(state, value) {
        state.memberId = value;
    },
    M_CHAT_DATA(store, payload) {
        store.chatData = payload
    },
    M_USER_ACTIVE_INDEX(store, payload) {
        store.userActiveIndex = payload
    },
    SET_LIST_GIFT(state, value) {
        state.listGift = value;
    },
    SET_GIFTS_EFFECTS(state, value) {
        state.isGiftsEffects = value;
    },
    SET_MONEY_REFRESH(state, value) {
        state.moneyRefresh = value;
    },
    SET_WATCH_FUN(state, bool) {
        state.watchFun = bool;
    },
    SET_IS_EFFECTS(state, value) {
        state.isEffects = value;
    },
    SET_IS_LIVEPAGE(state, value) {
        state.isLivePage = value;
    },
    SET_GIFT_TOP3(state, value) {
        state.giftTop3 = value;
    },
    SET_CAR_EFFECTLIST(state, value) {
        state.carEffectList = value;
    },
    SET_SHOWVIDEOCOVER(state, bool) {
        state.showVideoCover = bool;
    },
    SET_SHOW_LOADING(state, bool) {
        state.isLoading = bool
    },
    SET_LIVE_DATA_LIST(state, arr) {
        state.liveDataList = arr;
    },
    CLEAR_LIVE_DATA_LIST(state, value) {
        state.liveDataList = [];
    },
    SET_CHAT_BOX_SHOW(state, bool) {
        state.isShowChatBox = bool
    },
    SET_IS_LOGIN_CHAT(state, bool) {
        state.isLoginChatSuccess = bool
    },
    SET_MAIN_SWITCH(state, bool) {
        state.mainSwitch = bool
        localStorage.setItem("mainSwitch", bool)
    },
    SET_SHOW_EFFCET(state, bool) {
        state.isShowEffect = bool
    },
    SET_SHOW_SET(state, bool) {
        state.isShowSetInfo = bool
    },
    SET_PREVIEW_CONFIG(state, obj) {
        state.previewConfig = obj
    },
    SET_SELECT_VALUE(state, value) {
        state.selectValue = value
    },
    CLEAR_SELECT_VALUE(state) {
        state.selectValue = ''
    },
    SET_DIALOG_POP(state, value) {
        state.isShowGirl = value
    },
};

const actions = {
    // 排行榜
    getGiftTop3Fn({ commit }, params) {
        return APIS.getLiveRank(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == 200) {
                        commit('SET_GIFT_TOP3', data || []);
                    }
                    return res;
                }
            });
    },
    // vip座驾特效
    getCarEffectListFn({ commit }, params) {
        return APIS.getCarEffectList(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == '1') {
                        commit('SET_CAR_EFFECTLIST', data.listGift || []);
                    }
                    else {
                        Toast.fail(res.msg);
                    }
                    return res;
                }
            });
    },
    // 关闭特效
    getIsEffectsFn({ commit }, params) {
        commit('SET_IS_EFFECTS', params);
    },
    // 全屏开关
    getIsLivePageFn({ commit }, params) {
        commit('SET_IS_LIVEPAGE', params);
    },
    getPreviewConfig({ commit }, params) { //获取直播预览配置
        APIS.postGets(params).then((res) => {
            if(res.code == 200 && res.data?.data?.live_closed_img?.value) {
                commit('SET_PREVIEW_CONFIG', res.data?.data?.live_closed_img?.value);
            }
        })
    }
};

// getters
const getters = {
    M_FooterList(state) {
        return state.footerList
    },
    M_token(state) {
        return state.token
    },
    M_USER_DATA(state) {
        return state.userInfoData
    },
    M_chatData(state) {
        return state.chatData
    },
    M_userActiveIndex(state) {
        return state.userActiveIndex
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
